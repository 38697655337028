
<template>
    <div class="accordion-media-switch">
        <div class="accordion-media-switch__page-container">
            <div class="accordion-media-switch__content-container">
                <div class="accordion-media-switch__grid">
                    <div class="accordion-media-switch__grid-column">
                        <accordion-element
                            @activePanelChanged="activePanelChanged"
                            :panels="panels"
                            :settings="{ singlePanelMode: true }" />
                    </div>

                    <div class="accordion-media-switch__grid-column">
                        <image-element
                            v-if="activePanel.media && activePanel.media.image"
                            :key="activePanel.media.image.url"
                            :image="activePanel.media.image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AccordionElement from '@/components/elements/AccordionElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'AccordionMediaSwitchElement',
    components: {
        AccordionElement,
        ImageElement,
    },
    props: {
        panels: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            activePanel: null,
        }
    },
    computed: {
    },
    created() {
        this.activePanel = this.panels[0];
    },
    methods: {
        activePanelChanged(panel) {
            this.activePanel = panel;
        },
    }
}
</script>

