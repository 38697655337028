<template>
    <div class="view-nugget-detail">

        <page-header
            :has-search="false"
            :use-scroll-progress="true"
            :back-button="!isScorm"
            :no-usermenu="true"
        >
            <content-navigation
                v-if="!!getJumpmarks.length"
                slot="sticky"
                :items="getContentNavigationItems"
                />

            <div v-if="getOverviewButton" class="overview-slot" slot="action">
                <router-link :to="{ path: `/${getOverviewButton}`}" class="overview-button">
                    {{ t('overview') }}
                    <svgicon name="overview" />
                </router-link>
            </div>

        </page-header>

        <div class="section-dimensioning section-dimensioning--large content-dimensioning">

            <template v-for="section in getSections">

                <div
                    v-if="section.type =='contentNavigationItem'"
                    :id="`jumpmark-${section.id}`"
                    :key="`jumpmark-${section.id}`"
                ></div>

                <base-section
                    v-else
                    :key="`nugget-section-${section.id}`"
                    :section="section">
                </base-section>
            </template>

            <finish-module
                v-if="getFinishModuleSection && getTrackingId"
                :tracking-id="getTrackingId"
                :finish-module="getFinishModuleSection" />

        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    NUGGET_FETCH_NS,
    NUGGET_SET_NS,
    ME_GET_NS,
    SEARCH_TOGGLE_RESULTS_NS,
    TRACK_VISIT_NS,
    META_SET_NS,
    REQUIREMENTS_MATCH_NS,
} from "@/store/actions.type";

import ScormTracker from '@/common/tracking/ScormTracker';

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import NuggetComponent from '@/components/NuggetComponent.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import RecommendationsComponent from '@/components/RecommendationsComponent.vue';
import ContentNavigation from '@/components/elements/navigation/ContentNavigation.vue';
import BreadcrumbsElement from '@/components/elements/BreadcrumbsElement.vue';
import FinishModule from '@/components/elements/quiz/FinishModule.vue';

export default {
    name: 'NuggetDetail',

    components: {
        PageHeader,
        PageFooter,
        NuggetComponent,
        BaseSection,
        RecommendationsComponent,
        BreadcrumbsElement,
        ContentNavigation,
        FinishModule,
    },

    props: {
        content: Object,
        alternativeBrand: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('nugget', {
            getSections: 'getSections',
            getJumpmarks: 'getJumpmarks',
            getRecommendations: 'getRecommendations',
            getMeta: 'getMeta',
            isLoadingNugget: 'isLoading',
            getTrackingId: 'getTrackingId',
            getFinishModuleSection: 'getFinishModuleSection',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingNugget;
        },

        isScorm() {
            return window.EXPORT_TYPE_SCORM;
        },

        getBackButton() {
            return {
                link: () => this.$router.back(),
                label: this.t('overview'),
                icon: 'overview'
            };
        },

        hideBreadcrumbs() {
            return window.EXPORT_TYPE_SCORM;
        },

        getContentNavigationItems() {

            if (!this.getJumpmarks || !this.getJumpmarks.length) {
                return [];
            }

            return this.getJumpmarks.map((item) => {
                return {
                    'label': item.jumpmark,
                    'anchor': `#jumpmark-${item.id}`
                }
            });
        },

        getOverviewButton() {
            if (!this.getFinishModuleSection) {
                return;
            }

            const { overviewUri } = this.getFinishModuleSection.component.finishModule;

            return overviewUri;
        },
    },

    async beforeRouteEnter(to, from, next) {

        if (!window.EXPORT_TYPE_SCORM && !await store.dispatch(REQUIREMENTS_MATCH_NS, to.params.slug)) {
            return next({ name: 'forbidden' });
        }

        const [ uitextResult, nuggetResult ] = await Promise.all([
            await store.dispatch(UITEXTS_FETCH_NS),
            await store.dispatch(NUGGET_FETCH_NS, to.params.slug)
        ]);

        if (!window.EXPORT_TYPE_SCORM) {
            await store.dispatch(ME_GET_NS);
        }

        return next();
    },

    async beforeRouteUpdate(to, from, next) {

        if (!window.EXPORT_TYPE_SCORM && !await store.dispatch(REQUIREMENTS_MATCH_NS, to.params.slug)) {
            return next({ name: 'forbidden' });
        }

        await store.dispatch(NUGGET_FETCH_NS, to.params.slug);
        store.dispatch(SEARCH_TOGGLE_RESULTS_NS, false);

        return next();
    },

    beforeRouteLeave(to, from, next) {

        if (to.name == 'nugget' || to.name == 'collection' || to.name == 'entry') {
            this.pushBreadcrumb({
                text: 'Forward',
                to: { path: from.path }
            });
        } else if (to.name == 'training-hub' || to.name == 'home' || to.name == 'stream') {
            this.popBreadcrumb();
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(NUGGET_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);

            store.dispatch(META_SET_NS, {
                isAlternativeBrand: this.alternativeBrand,
                canAccessCp: false,
            });
        }

        if (this.getMeta) {
            const { headline, subline } = this.getMeta;
            document.title = `ALDI - ${headline || subline || ''}` ;
        }

        if (!this.getFinishModuleSection && this.getTrackingId && !window.EXPORT_TYPE_SCORM) {
            store.dispatch(TRACK_VISIT_NS, {
                entryUid: this.getTrackingId
            });
        }
    },

    async mounted() {

        // if (window.EXPORT_TYPE_SCORM) {
        //     const scormTracker = new ScormTracker();
        //     await this.$nextTick()
        //     scormTracker.trackCompletion();
        // }
    },

    methods: {
        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),
    },
}
</script>
