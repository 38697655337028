<template>
    <div class="hub-keyvisual">
        <div class="hub-keyvisual__page-container">

            <image-element
                v-if="keyvisual.image"
                class="hub-keyvisual__background"
                :image="keyvisual.image"
                :settings="{
                    sizing: 'cover'
                }" />

            <div class="hub-keyvisual__content-container">
                <div class="hub-keyvisual__grid">
                    <div class="hub-keyvisual__grid-column">
                        <v-runtime-template
                            class="hub-keyvisual__subline"
                            :template="`<span>${ t('welcome-to-hub') }</span>`" />

                        <span class="hub-keyvisual__headline">{{ t('aldi-training-hub') }}</span>
                    </div>
                </div>
            </div>

            <div v-if="getEvents && !!getEvents.length" class="hub-keyvisual__events">
                <div class="swiper-container hub-keyvisual__slider" ref="slider">
                    <div class="swiper-wrapper">
                        <router-link
                            :class="{
                                'swiper-slide': true,
                                'hub-keyvisual__event': true,
                                'hub-keyvisual__event--loading': loadingEventIndex == index,
                            }"
                            v-for="(event, index) in getEvents"
                            :key="`event-item-${event.id}-${index}`"
                            :to="{ path: `/${event.url}` }"
                            @click.native="(ev) => handleEventClick(ev, index)"
                        >
                            <div class="hub-keyvisual__event-wrapper">
                                <div class="hub-keyvisual__event-image">
                                    <image-element
                                        v-if="event.image"
                                        :image="event.image"
                                        :settings="{
                                            sizing: 'cover'
                                        }" />

                                    <div v-if="loadingEventIndex == index" class="tile__loader">
                                        <loader-element :loading="loadingEventIndex == index" />
                                    </div>
                                </div>
                                <div class="hub-keyvisual__event-content">
                                    <span v-if="event.introtext.subline" class="hub-keyvisual__event-subline">{{ event.introtext.subline }}</span>
                                    <span class="hub-keyvisual__event-headline">{{ event.introtext.headline }}</span>
                                </div>
                                <div class="hub-keyvisual__event-visit">
                                    <svgicon-wrapper name="arrow-down-fine" class="hub-keyvisual__event-arrow" />
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <!-- <div class="swiper-pagination slider-pagination slider-pagination--thin"></div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    TRAINING_HUB_FETCH_EVENTS_NS,
} from "@/store/actions.type";

import Swiper from 'swiper';
import VRuntimeTemplate from "v-runtime-template/dist/v-runtime-template";

import ImageElement from '@/components/elements/ImageElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';
export default {
    name: 'HubKeyvisual',

    components: {
        VRuntimeTemplate,
        ImageElement,
        LoaderElement,
    },

    props: {
        keyvisual: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                }
            }
        }
    },

    data() {
        return {
            slider: null,
            loadingEventIndex: -1,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('trainingHub', {
            isLoadingEvents: 'isLoadingEvents',
            getEvents: 'getEvents',
        }),

        ...mapGetters('me', {
            getFirstname: 'getFirstname',
        }),

        user_firstname() {
            return this.getFirstname;
        },

    },

    async mounted() {
        await store.dispatch(TRAINING_HUB_FETCH_EVENTS_NS);

    },

    updated() {
        this.createSlider();
    },

    methods: {

        createSlider() {
            const { slider } = this.$refs;

            if (!slider) { return; }

            this.slider = new Swiper(slider, {
                preventClicks: false,
                centeredSlides: false,
                slideToClickedSlide: false,
                watchOverflow: true,
                slidesPerView: 'auto',
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                }
            });

            this.slider.on('resize', () => {
                this.slider.update();
            });
        },

        handleMediaLoaded() {
            this.slider && this.slider.update();
        },

        handleEventClick(event, index) {

            if (this.loadingEventIndex != -1) {
                event.preventDefault();
                return false;
            }

            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loadingEventIndex = index;
            }

            this.$emit('eventClick');
        },
    },

    beforeDestroy() {
        // this.slider && this.slider.destroy();
    }

}
</script>
