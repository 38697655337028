<template>
    <div v-if="!isLoading" class="view-nugget-collection" :class="[ `view-nugget-collection--type-overview` ]">

        <page-header
            :use-scroll-progress="false"
            :back-button="false"
            :no-usermenu="true"
            :transparent="true"
        >

        </page-header>

        <div class="section-dimensioning section-dimensioning--medium content-dimensioning">
            <base-section
                v-if="getKeyvisual"
                :section="getKeyvisual"
                :id="`jumpmark-start`" />
        </div>

        <div>
            <div class="section__page-container">
                <div class="section__content-container">
                    <div class="view-nugget-collection__grid">
                        <div
                            v-for="(nugget, index) in getNuggets"
                            :key="`nugget-index-${nugget.id}`"
                            class="view-nugget-collection__tile"
                            :class="{
                                'view-nugget-collection__tile--completed': isModuleCompleted(nugget.uid)
                            }"
                        >
                            <fluid-tile
                                :nugget="nugget"
                                :clamp="false"
                                :index="`${t('module')} ${index + 1}`" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    COLLECTION_OVERVIEW_FETCH_NS,
    COLLECTION_OVERVIEW_SET_NS,
    UITEXTS_FETCH_NS,
    ME_GET_NS,
    TRACK_VISIT_NS,
    META_SET_NS,
    REQUIREMENTS_MATCH_NS,
    SCORM_INIT_DATA_NS,
} from "@/store/actions.type";

import ScormTracker from '@/common/tracking/ScormTracker';

import PageHeader from '@/components/common/PageHeader.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import FluidTile from '@/components/elements/tiles/FluidTile.vue';

export default {
    name: 'OverviewView',

    components: {
        PageHeader,
        BaseSection,
        FluidTile,
    },

    props: {
        content: Object,
        alternativeBrand: {
            type: Boolean,
            default: false,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('collectionOverview', {
            isLoadingCollection: 'isLoading',
            getNuggets: 'getNuggets',
            getKeyvisual: 'getKeyvisual',
            getTrackingId: 'getTrackingId',
        }),

        ...mapGetters('scorm', {
            isModuleCompleted: 'isModuleCompleted'
        }),

        isLoading() {
            return this.isLoadingCollection;
        },

        isScorm() {
            return window.EXPORT_TYPE_SCORM;
        },
    },

    async beforeRouteEnter(to, from, next) {

        await store.dispatch(UITEXTS_FETCH_NS);
        if (!window.EXPORT_TYPE_SCORM) {
            await store.dispatch(ME_GET_NS);
        }
        const result = await store.dispatch(COLLECTION_OVERVIEW_FETCH_NS, to.params.slug);

        // if (!result) {
        //     return next({ name: 'home' });
        // }

        return next();
    },

    beforeRouteLeave(to, from, next) {


        if (to.name == 'entry') {
            this.pushBreadcrumb({
                text: 'Forward',
                to: { path: from.path }
            });
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(COLLECTION_OVERVIEW_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            if (!window.EXPORT_TYPE_SCORM) {
                await store.dispatch(ME_GET_NS);
            }

            store.dispatch(META_SET_NS, {
                isAlternativeBrand: this.alternativeBrand,
                canAccessCp: false,
            });
        }

        // if (!window.EXPORT_TYPE_SCORM) {
        //     store.dispatch(TRACK_VISIT_NS, {
        //         entryUid: this.getTrackingId
        //     });
        // }
    },

    async mounted() {

        const modules = this.getNuggets.reduce((carry, item) => {
            return {
                ...carry,
                [item.uid]: false,
            };
        }, {});

        store.dispatch(SCORM_INIT_DATA_NS, {
            modules
        });
    },

    methods: {

    },
}
</script>
