<template>
    <div
        v-if="introtext"
        :class="{
            'introtext': true,
            [`introtext--${type}`]: true,
        }"
    >
        <div v-if="introtext.subline || introtext.headline" class="introtext__heading">
            <span
                v-if="introtext.subline"
                class="introtext__heading-subline"
                v-html="subline"
            ></span>
            <span v-if="introtext.headline" class="introtext__heading-headline" v-html="headline"></span>
        </div>

        <v-runtime-template
            v-if="introtext.copytext"
            ref="copytext"
            :class="{
                'introtext__text': true,
                'introtext__text--follow-tables': hasFollowTables
            }"
            :template="`<div>${ introtext.copytext }</div>`"
        />

        <div v-if="this.$slots['before-button']" class="introtext__extra">
            <slot name="before-button" />
        </div>
        <div v-if="introtext.button || this.$slots['extra-button']" class="introtext__buttons">
            <button-element v-if="introtext.button" :button="introtext.button" @button-entry="(event) => $emit('button-entry', event)"></button-element>
            <slot name="extra-button" />
        </div>
        <slot />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lineClamp } from '@/common/utility/lineClamp';
import VRuntimeTemplate from "v-runtime-template/dist/v-runtime-template";

import ButtonElement from "@/components/elements/ButtonElement.vue";
import hyphenate from "@/common/polyfills/hyphenate";

const safeWords = [
    'ALDI',
];

export default {
    name: "IntrotextElement",

    components: {
        ButtonElement,
        VRuntimeTemplate
    },

    props: {
        type: {
            type: String,
            default: () => {
                return "type-1";
            }
        },
        introtext: {
            type: Object,
            default: () => {
                return {
                    headline: null,
                    subline: null,
                    copytext: null,
                    button: null
                };
            }
        },
        noHyphens: {
            type: Boolean,
            default: false
        },
        extraContext: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },

    data() {
        return {
            isLoaded: false,
            hasFollowTables: false
        };
    },

    computed: {
        ...mapGetters("me", {
            getFirstname: "getFirstname"
        }),

        subline() {

            let text = this.introtext.subline;
            safeWords.forEach((word) => {
                text = text.replace(word, `<span class="no-hy">${word}</span>`);
            });

            return text;
        },

        headline() {
            return this.noHyphens
                ? this.introtext.headline
                : this.hyphenatedHeadline;
        },

        user_firstname() {
            return this.getFirstname;
        },

        event_end_date() {
            return this.extraContext && this.extraContext.hasOwnProperty('event_end_date') ? this.extraContext['event_end_date'] : '';
        },
    },

    asyncComputed: {
        async hyphenatedHeadline() {

            if (!this.introtext.headline) {
                return '';
            }

            let text = this.introtext.headline;
            safeWords.forEach((word) => {
                text = text.replace(word, `<span class="no-hy">${word}</span>`);
            });

            const hyphenatedText = await hyphenate(text);
            this.$emit("loaded");

            return hyphenatedText;
        }
    },

    async mounted() {
        await this.$nextTick();

        const { copytext } = this.$refs;

        if (copytext && copytext.$el.querySelector('table + table')) {
            this.hasFollowTables = true;
        }
    },

    updated() {
        const { copytext } = this.$refs;

        if (copytext && copytext.$el.querySelectorAll('a')) {
            const allLinks = copytext.$el.querySelectorAll('a');
            for (let i = 0; i < allLinks.length; i++) {
                const currentLink = allLinks[i];
                currentLink.setAttribute('target', '_blank');
            }
        }
    },
};
</script>
