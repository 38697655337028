<template>
    <headroom
        :offset="headerHeight"
        :speed="300"
        easing="cubic-bezier(0.23, 1, 0.32, 1)"
        :downTolerance="15"
        @pin="headroomPinned"
        @unpin="headroomUnPinned"
        :class="{
            'page-header__headroom--disabled': headroomDisabled || scrollHeaderDisabled,
            'page-header--transparent': transparent,
        }"
        :disabled="headroomDisabled || scrollHeaderDisabled"
    >
        <header class="page-header">
            <div class="page-header__page-container" ref="header">

                <div class="page-header__logo">
                    <a href="/" @click="handleLogoClick" class="page-header__logo-link">
                        <svgicon v-if="$mq == 'xs'" name="logo-flat" class="no-rtl" :original="true" />
                        <svgicon v-else :name="getLogoName" class="no-rtl" :original="true"   />
                    </a>
                </div>

                <div
                    :class="{
                        'page-header__slot': true,
                        'page-header__slot--search': hasSearch,
                        'page-header__slot--greeting': !hasSearch && getFirstname,
                        [slotClass]: true,
                    }"
                >
                    <div v-if="hasSearch" class="page-header__search">
                        <transition name="search">
                            <div class="page-header__search-wrapper" v-show="showSearchMenu">
                                <search-element ref="searchElement" @searched="hideSearchMenu" @toggle="toggleSearchMenu"/>
                            </div>
                        </transition>
                    </div>

                    <div v-if="false && !isAlternativeBrand && hasSearch" class="page-header__greeting">Hallo <span v-if="getFirstname">{{ getFirstname }}</span></div>
                    <slot />
                    {{ hashtag }}
                </div>

                <div v-if="hasSearch || !noUsermenu || $slots.action" :class="['page-header__action', actionSlotClass]" >
                    <span v-if="hasSearch && showSearchMenuButton" class="page-header__filter-button" @click="toggleSearchMenu"><svgicon-wrapper name="search" /></span>
                    <slot name="action" />
                    <user-menu v-if="!noUsermenu">
                        <template slot="usermenuExtraListItems">
                            <slot name="usermenuExtraListItems" />
                        </template>
                    </user-menu>
                </div>

                <div v-if="hasBackButton || hasFilterButton" class="page-header__floating-buttons">
                    <span
                        v-if="(!hasFilterButton || (hasFilterButton && !getIsShowingFilters)) && hasBackButton"
                        class="page-header__floating-button"
                        @click="handleBackButtonClick"
                    >
                        <span class="page-header__floating-button-wrapper">
                            <svgicon-wrapper name="arrow-right" dir="up" />
                        </span>
                    </span>

                    <span
                        v-if="hasFilterButton"
                        :class="{
                            'page-header__floating-button': true,
                        }"
                        @click="handleFilterButtonClick"
                    >
                        <span class="page-header__floating-button-wrapper">
                            <svgicon-wrapper v-if="getIsShowingFilters" name="cross" />
                            <svgicon-wrapper v-else name="filter" />
                        </span>
                    </span>
                </div>

            </div>

            <div v-if="useScrollProgress || $slots.sticky" class="page-header__sticky" ref="sticky">
                <slot v-if="!(hasSearch && getIsSearchResultsVisible)" name="sticky" />
                <scroll-progress v-if="useScrollProgress"></scroll-progress>
            </div>
        </header>
    </headroom>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { headroom } from 'vue-headroom';
import debounce from 'debounce';

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import ScrollProgress from '@/components/ScrollProgress.vue';
import UserMenu from '@/components/elements/navigation/UserMenu.vue';
import SearchElement from '@/components/elements/SearchElement.vue';

import store from "@/store";
import {
    HEADER_PIN_CHANGED_NS,
    HEADER_HEIGHT_CHANGED_NS,
    ACTUAL_HEADER_HEIGHT_CHANGED_NS,
    SEARCH_TOGGLE_RESULTS_NS,
    SEARCH_TOGGLE_FILTERS_NS,
    SEARCH_TOGGLE_MENU_NS,
} from "@/store/actions.type";

export default {
    name: 'PageHeader',

    components: {
        headroom,
        ScrollProgress,
        UserMenu,
        SearchElement,
        TransitionExpand,
    },

    props: {
        backButton: Boolean,
        slotClass: {
            type: String,
            default: '',
        },
        actionSlotClass: String,
        headroomDisabled: {
            type: Boolean,
            default: false
        },
        useScrollProgress: {
            type: Boolean,
            default: false
        },
        noUsermenu: {
            type: Boolean,
            default: false,
        },
        hasSearch: {
            type: Boolean,
            default: false
        },
        transparent: Boolean,
    },

    computed: {

        ...mapState('breadcrumbs', {
            breadcrumbs: state => state.breadcrumbs,
        }),

        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        ...mapGetters('me', {
            getFirstname: 'getFirstname',
        }),

        ...mapGetters('search', {
            getIsSearchResultsVisible: 'getIsSearchResultsVisible',
            getIsShowingFilters: 'getIsShowingFilters',
            getIsSearchMenuVisible: 'getIsSearchMenuVisible',
        }),

        showSearchMenu() {
            return (this.getIsSearchMenuVisible && ['xs', 'sm'].indexOf(this.$mq) !== -1) || ['md', 'lg'].indexOf(this.$mq) !== -1;
        },

        showSearchMenuButton() {
            return ['xs', 'sm'].indexOf(this.$mq) !== -1 || ['md', 'lg'].indexOf(this.$mq) !== -1;
        },

        showFilterButton() {
            return ['xs', 'sm'].indexOf(this.$mq) !== -1;
        },

        hasBackButton() {
            if (window.EXPORT_TYPE_SCORM) {
                return false;
            }

            const backToHub = this.backButton && [ 'error', 'forbidden' ].indexOf(this.$router.currentRoute.name) !== -1;

            return (this.backButton && (this.breadcrumbs && !!this.breadcrumbs.length)) || this.getIsSearchResultsVisible || backToHub;
        },

        hasFilterButton() {
            if (window.EXPORT_TYPE_SCORM) {
                return false;
            }

            return this.getIsSearchResultsVisible && this.showFilterButton;
        },

        getLogoName() {
            let logo = 'logo';

            if (this.isAlternativeBrand && ['xs', 'sm', 'md'].indexOf(this.$mq) == -1) {
                logo = 'logo_mark';
            }

            return logo;
        },

        hashtag() {
              return window.document.documentElement.getAttribute('lang') == 'en' ? '#ItsAboutYou' : '#ESGEHTUMDICH';
        },
    },

    data() {
        return {
            headerHeight: 0,
            actualHeaderHeight: 0,
            scrollHeaderDisabled: false,
            // searchMenuVisible: false,
            pinned: true,
        }
    },

    watch: {
        getIsSearchResultsVisible(newValue) {
            this.setHeaderHeight();
        }
    },

    mounted() {
        this.setHeaderHeight();

        window.addEventListener('resize', this.setHeaderHeight);
    },

    methods: {
        setHeaderHeight: debounce(function () {
            if (!this.$refs) { return; }
            const { header, sticky } = this.$refs;

            if (header) {
                this.headerHeight = header.getBoundingClientRect().height;
            }

            if (sticky) {
                this.headerHeight += sticky.getBoundingClientRect().height;
            }

            if (!this.transparent) {
                document.body.style.paddingTop = `${this.headerHeight}px`;
            } else {
                document.body.style.paddingTop = '';
            }

            store.dispatch(HEADER_HEIGHT_CHANGED_NS, this.headerHeight);
        }, 100),

        setActualHeaderHeight: debounce(function() {

            if (!this.$refs) { return; }
            const { header, sticky } = this.$refs;

            this.actualHeaderHeight = sticky ? sticky.getBoundingClientRect().height : 0;

            if (this.pinned) {
                this.actualHeaderHeight += header.getBoundingClientRect().height;
            }

            store.dispatch(ACTUAL_HEADER_HEIGHT_CHANGED_NS, this.actualHeaderHeight);
        }, 100),

        headroomPinned() {
            this.pinned = true;
            store.dispatch(HEADER_PIN_CHANGED_NS, true);

            this.setActualHeaderHeight();
        },

        headroomUnPinned() {
            this.pinned = false;
            store.dispatch(HEADER_PIN_CHANGED_NS, false);

            this.setActualHeaderHeight();
        },

        async toggleSearchMenu() {
            store.dispatch(SEARCH_TOGGLE_MENU_NS, !this.getIsSearchMenuVisible)
            // this.searchMenuVisible = !this.searchMenuVisible;

            if (this.getIsSearchMenuVisible) {
                await this.$nextTick();
                this.$refs.searchElement.focus();
            }
        },

        hideSearchMenu() {
            // this.searchMenuVisible = false;
            this.$refs.searchElement.blur();
        },

        handleBackButtonClick() {
            if (this.getIsSearchResultsVisible) {
                store.dispatch(SEARCH_TOGGLE_RESULTS_NS, false);
            } else {
                const backToHub = this.backButton && [ 'error', 'forbidden' ].indexOf(this.$router.currentRoute.name) !== -1;
                if (backToHub) {
                    this.$router.push({ name: 'home' })
                } else {
                    this.$router.go(-1);
                }
            }
        },

        handleFilterButtonClick() {
            store.dispatch(SEARCH_TOGGLE_FILTERS_NS, !this.getIsShowingFilters);
        },

        handleLogoClick() {
            this.$gtag.event('sth.click.logo', {
                'event_category': 'click',
                'event_label': 'Logo click',
                'value': 1
            });
        },

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setHeaderHeight);
    }
}
</script>
