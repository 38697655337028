/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lined-arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 40 40',
    data: '<g><g><g><path pid="0" d="M0 8v24c0 4.4 3.6 8 8 8h24c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8H8C3.6 0 0 3.6 0 8z" fill-rule="evenodd" clip-rule="evenodd" _fill="#ff7800"/><path pid="1" d="M27.9 16.1c-.4 0-.7.1-1 .4l-7.1 7.1-7.1-7.1c-.5-.5-1.4-.5-1.9 0-.3.3-.4.6-.4 1s.1.7.4 1l8.1 8.1c.3.3.6.4 1 .4s.7-.1 1-.4l8.1-8.1c.3-.3.4-.6.4-1-.1-.8-.7-1.4-1.5-1.4z" _fill="#fff"/></g></g></g>'
  }
})
